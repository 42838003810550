
@keyframes colorchange {
    0% {
        color: red;
    }
    25% {
        color: yellow;
    }
    50% {
        color: blue;
    }
    75% {
        color: green;
    }
    100% {
        color: red;
    }
}
